<template>
    <v-dialog v-model="dialog" persistent  width="800">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="isNew" v-on="on" v-bind="attrs" color="green" depressed class="white--text">

                {{ $t("Create Variable") }}
            </v-btn>
            <v-icon v-if="!isNew" @click="fetchdata" v-on="on" v-bind="attrs" color="blue">mdi-pen</v-icon>
        </template>
        <v-card>
            <v-card-title class="justify-center">
                <h1>{{ isNew ? $t("Create Variable") : $t('edit') }}</h1>
            </v-card-title>
            <v-card-text>
                <v-container fluid>
                    <v-row justify="center">
                        <v-col md="4">
                            <v-text-field :dark="$store.state.isDarkMode" :label="$t('date')" outlined
                                    v-model="structure.date">
                                </v-text-field>
                        </v-col>
                        <v-col md="4">
                            <v-text-field :dark="$store.state.isDarkMode" :label="$t('description')" outlined
                                    v-model="structure.desc">
                                </v-text-field>
                        </v-col>
                       
                    </v-row>
                    <template v-for="(emp, idx) in structure.student_variables">
                        <v-row class="no-padding">
                            <v-col>
                                <AutoCompleteField :label="$t('student name')" type="regRecord" endPoint="/student/auto-complete" outlined
                                    v-model="structure.student_variables[idx].registration_record_id" />

                            </v-col>
                            <v-col>
                                <AutoCompleteField :label="$t('Variable Name')" endPoint="/school-variable" outlined
                                    v-model="structure.student_variables[idx].variable_id" />
                            </v-col>
                            <v-col>
                                <v-text-field :dark="$store.state.isDarkMode" :label="$t('notes')" outlined
                                    v-model="structure.student_variables[idx].notes">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <br>

                        <br>
                    </template>
                    <v-btn type="button" @click="addAnotherStdVar">{{ $t('add') }}
                    </v-btn>
                
                    <v-col v-if="isNew">
                        <v-col md="2" style="display: flex; justify: start">
                            <div>
                                <input type="file" id="fileInput" class="hidden-input" multiple
                                    @change="handleFileChange" />
                                <label for="fileInput" class="file-label">
                                    <u class="btn">
                                        <v-icon size="20" color="white">mdi-plus</v-icon>{{ $t("add attachment") }}</u>
                                </label>
                                <div>
                                    <ul>
                                        <li v-for="file in structure.files" :key="file.name">
                                            {{ file.name }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </v-col>
                    </v-col>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn color="green" depressed class="white--text" @click="submit" :loading="btnLoading">{{ isNew ? $t("create") : $t('edit')
                }}</v-btn>
                <v-btn color="blue darken-1" text @click="clearData">
                    {{ $t('cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
<script>
import axios from "axios";
import AutoCompleteField from "../../components/core/AutoCompleteField.vue";

export default {
    components: {
        AutoCompleteField,
    },
    data() {
        return {
            btnLoading: false,
            dialog: false,
            dataUpdate: null,
            structure: {
                date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10),
                desc: null,
                student_variables: [
                    {
                        registration_record_id: '',
                        variable_id: '',
                        notes: '',
                    }
                ],
                files: [],

            },
        };
    },
    props: {
        isNew: {
            type: Boolean,
            default: true,
        },
        item: {
            type: Object,
            default: () => {
                return {};
            },
        }
    },
    methods: {
        async fetchdata(){
            if (!this.isNew) {

            const res = await axios.get(`/student-variable/${this.item.id}`)
            this.dataUpdate = res.data.data;
            this.structure = this.dataUpdate;
            for(let i=0;i<this.dataUpdate.student_variables.length;i++){
                this.structure.student_variables[i].registration_record_id = this.dataUpdate.student_variables[i].registration_record;
                this.structure.student_variables[i].variable_id = this.dataUpdate.student_variables[i].variable;
            }
           
           

            }
        },
        async submit() {
            try {
                this.btnLoading = true;
                let formData = new FormData();
                for (let i = 0; i < this.structure.files.length; i++) {
                    formData.append('files[]', this.structure.files[i]);
                }
                for (let i = 0; i < this.structure.student_variables.length; i++) {
                    formData.append(`student_variables[${i}][notes]`, this.structure.student_variables[i].notes);
                    formData.append(`student_variables[${i}][variable_id]`, this.structure.student_variables[i].variable_id.id ?? this.structure.student_variables[i].variable_id);
                    formData.append(`student_variables[${i}][registration_record_id]`, this.structure.student_variables[i].registration_record_id.id ??  this.structure.student_variables[i].registration_record_id);
                }
                formData.append('date', this.structure.date);
                formData.append('desc', this.structure.desc);
                if (this.isNew) {
                    await axios.post("/student-variable", formData);
                    this.$Notifications(
                        this.$t('add success'),
                        { rtl: true, timeout: 2000 },
                        "success"
                    );
                } else {
                    await axios.post(`/student-variable/update/${this.dataUpdate.id}`, formData);
                    this.$Notifications(
                        this.$t('edit success'),
                        { rtl: true, timeout: 2000 },
                        "success"
                    );
                }
                this.$emit("Submitted");
                this.clearData();
            } catch (err) {
            } finally {
                this.btnLoading = false;

            }
        },
        addAnotherStdVar() {
            this.structure.student_variables.push({
                registration_record_id: '',
                variable_id: '',
                notes: '',
            })
        },
        handleFileChange(event) {
      const files = event.target.files;
      this.structure.files = Array.from(files);
    },
        clearData() {
            this.structure.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10);
            this.structure.desc = null;
            this.structure.student_variables = [
                {
                    registration_record_id: '',
                    variable_id: '',
                    notes: '',
                }
            ];
            this.structure.files = [];
            this.dialog = false;
        },
    },
   async created() {

        

    },
};
</script>
  
<style>


.btn {
  text-decoration: none;
  height: 41px;
  color: white;
  min-width: 73px;
  padding: 0 18.2222222222px;
  font-size: 1rem;
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-weight: 400;
  letter-spacing: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0;
  text-transform: uppercase;
  -webkit-transition-duration: 0.28s;
  transition-duration: 0.28s;
  -webkit-transition-property: opacity, -webkit-box-shadow, -webkit-transform;
  transition-property: opacity, -webkit-box-shadow, -webkit-transform;
  transition-property: box-shadow, transform, opacity;
  transition-property: box-shadow, transform, opacity, -webkit-box-shadow,
    -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
}

</style>